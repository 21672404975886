.date-range-selector {
    width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background: #f9f9f9;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease-in-out;
}

.header {
    text-align: center;
    margin-bottom: 20px;
}

.selected-dates {
    font-size: 14px;
    color: #555;
}

.calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
}

.date-cell {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.2s;
}

.date-cell:hover {
    background: #e0e0e0;
}

.selected-start {
    background: #4caf50;
    color: #fff;
}

.selected-end {
    background: #f44336;
    color: #fff;
}

.in-range {
    background: #81c784;
    color: #fff;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
