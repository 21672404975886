/* Full screen loading overlay */

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(205, 208, 255, 0.5); /* Optional: Adds a semi-transparent background */
  z-index: 1; /* Ensure the overlay is on top */
  }
  
  /* Optional: Style the body or other content to disable interaction during loading */
  body.loading {
    pointer-events: none;
    opacity: 0.5;
  }
  