.calendar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.toggle-button {
  padding: 10px 20px;
  border: none;
  background-color: #4caf50;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.toggle-button:hover {
  background-color: #45a049;
}

.calendar {
  width: 320px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  animation: slideDown 0.3s ease-in-out;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.calendar-header h3 {
  margin: 0;
}

.calendar-dates {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.date-cell {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.2s;
}

.date-cell:hover {
  background: #e0e0e0;
}

.selected-start {
  background: #4caf50;
  color: #fff;
}

.selected-end {
  background: #f44336;
  color: #fff;
}

.in-range {
  background: #81c784;
  color: #fff;
}

.selected-dates {
  margin-top: 10px;
  font-size: 14px;
}

@keyframes slideDown {
  from {
      opacity: 0;
      transform: translateY(-10px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}
