.dropdown-content {
    display: none;
    border: 1px solid #ddd;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    background-color: white;
    z-index: 1;
    width: 200px;
  }
  
  .dropdown-content label {
    display: block;
    padding: 8px;
  }
  
  .dropdown-content label:hover {
    background-color: #f1f1f1;
  }
  
  .show {
    display: block;
  }
  